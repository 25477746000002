:root {
  --main: #16499F;
  --orange: #febf28;
}

* {
  font-family: 'Poppins', sans-serif;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

button, input, select {
  font-family: 'Poppins', sans-serif;
}

a {
  text-decoration: none;
  color: var(--main);
}

a:hover {
  text-decoration: underline;
}

.alert-danger {
  background: #ffd5d5;
  color: #be1717;
  padding: 0.5rem 0.8rem;
  border-radius: 4px;
  margin: 0.5rem 0;
}

.container {
  max-width: 1100px;
  padding: 0 0.9rem;
  margin: 0 auto;
}

.text-primary {
  color: var(--main);
}

.text-muted {
  color: #888;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -0.4rem;
}

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12 {
  margin: 0 0.4rem;
}
.col-1 {
  width: calc((8.33%*1) - 0.8rem);
}
.col-2 {
  width: calc((8.33%*2) - 0.8rem);
}
.col-3 {
  width: calc((8.33%*3) - 0.8rem);
}
.col-4 {
  width: calc((8.33%*4) - 0.8rem);
}
.col-5 {
  width: calc((8.33%*5) - 0.8rem);
}
.col-6 {
  width: calc((8.33%*6) - 0.8rem);
}
.col-7 {
  width: calc((8.33%*7) - 0.8rem);
}
.col-8 {
  width: calc((8.33%*8) - 0.8rem);
}
.col-9 {
  width: calc((8.33%*9) - 0.8rem);
}
.col-10 {
  width: calc((8.33%*10) - 0.8rem);
}
.col-11 {
  width: calc((8.33%*11) - 0.8rem);
}
.col-12 {
  width: calc(100% - 0.8rem);
}

input, select {
  padding: 0.5rem 0.6rem;
  border: 1px solid #c9c9c9;
  border-radius: 4px;
  display: block;
  font-size: 0.9rem;
  width: 100%;
  margin-bottom: 0.4rem;
  background-color: #fff; 
}

.loading-screen {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: rgba(255,255,255,.8);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9;
  backdrop-filter: blur(10px);
}

fieldset {
  padding: 1rem !important;
  border-radius: 4px;
  border: 1px solid #dfdfdf !important;
  margin-bottom: 1rem !important;
}

fieldset h4 {
  display: flex;
  align-items: center;
}

fieldset h4 img {
  width: 100%;
  max-width: 30px;
  margin-right: 0.4rem;
}

fieldset h4 svg {
  color: var(--main);
  margin-right: 0.4rem;
}

header {
  background-color: var(--main);
  color: #fff;
  padding: 0.8rem 0.5rem;
}

header > div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

header img {
  width: 100%;
  max-width: 160px;
}

header h1 {
  font-size: 0px;
}

footer {
  padding: 1rem 0.4rem;
}

h2, h3, h4, h5, p, label {
  color: #666;
}

fieldset {
  border: none;
  margin: 1rem 0;
}

main {
  margin-top: 1.5rem !important;
  padding-bottom: 2rem !important; 
}

button[type=submit] {
  background-color: #14b75b;
  color: #fff;
  border: none;
  padding: 0.3rem 1rem;
  border-radius: 4px;
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12 {
    width: 100%;
  }
  button[type=submit] {
    width: 100%;
    padding: 0.8rem 1rem;
    font-size: 1.2rem;
  }
}