.lds-dual-ring {
  display: inline-block;
  width: 40px;
  height: 40px;
}
.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 32px;
  height: 32px;
  margin: 0px;
  border-radius: 50%;
  border: 4px solid var(--main);
  border-color: var(--main) transparent var(--main) transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}


@keyframes float {
  0%, 100% {
    transform: translateY(0px);
  } 50% {
    transform: translateY(-10px);
  }
}

.float {
  animation: float 1s infinite;
}
